import { useState, useEffect } from "react";

export const useOtp = () => {
  const otpLength = 6;
  const [codes, setCode] = useState(Array(otpLength).fill(""));
  const timerSeconds = 30;
  const [resendTimer, setResendTimer] = useState(timerSeconds);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (resendTimer > 0) {
      interval = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendTimer]);

  const resetTimer = () => {
    setResendTimer(timerSeconds);
  };

  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    if (RegExp(/^\d$/).exec(value) && index < codes.length) {
      const newOtp = [...codes];
      newOtp[index] = value;
      setCode(newOtp);
      if (value && index < codes.length - 1) {
        document.getElementById(`code-${index + 1}`)?.focus();
      }
    } else if (value === "" && index >= 0) {
      handleOtpBackspace(index);
    }
  };

  const handleOtpKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && index > 0) {
      e.preventDefault();
      handleOtpBackspace(index);
    } else if (/^\d+$/.test(e.key)) {
      e.currentTarget.value = e.key.toString();
      handleOtpValue(index, e.currentTarget.value);
    }
  };

  const handleOtpValue = (index: number, value: string) => {
    const newCode = [...codes];
    newCode[index] = value;
    setCode(newCode);
    if (index >= 0) {
      setTimeout(() => {
        if (index < codes.length - 1) {
          document.getElementById(`code-${index + 1}`)?.focus();
        } else {
          document.getElementById(`code-${index}`)?.blur();
        }
      }, 10);
    }
  };


  const handleOtpBackspace = (index: number) => {
    const newCode = [...codes];
    if (newCode[index]) {
      newCode[index] = "";
      setCode(newCode);
      return;
    }
    if (index > 0) {
      newCode[index - 1] = "";
      setCode(newCode);
      document.getElementById(`code-${index - 1}`)?.focus();
    }
  };
  const handleOtpPaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (pastedData.length <= otpLength) {
      const newCodes = [...codes];
      for (let i = 0; i < pastedData.length; i++) {
        const char = pastedData.charAt(i);
        if (index + i < otpLength && /^\d$/.test(char)) { // Check if char is a digit
          newCodes[index + i] = char; // Assign only if it's a digit
        }
      }
      setCode(newCodes);
    }
  };
  return {
    codes,
    setCode,
    handleOtpInput,
    handleOtpKeyDown,
    resendTimer,
    resetTimer,
    handleOtpPaste,
  };
};