import { useState, useEffect, useRef } from "react";
import Images from "../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useFetchUser, useSendVerificationCode, useUpdateUserProcessStatus, useVerifyUserVerificationCode } from "../services/user.service";
import { useOtp } from '../helpers/otp';
import { storage } from "../utils/storage";
import IResponse from "../types/response";
import useRenewAccessToken, { isNotNullUndefined, isNotNullUndefinedBlank, statusWiseOnboardingRedirectToPage, isUserStatusHasPermission, isTokenValid } from '../helpers/common';
import * as Sentry from "@sentry/react";

const Authorization = () => {
  const verificationData = {
    userId: "",
    email: "",
    codeType: ""
  };

  const navigate = useNavigate();
  const { codes, setCode, handleOtpInput, handleOtpKeyDown, resendTimer, resetTimer, handleOtpPaste } = useOtp();
  const userId = useRef("");
  const email = useRef("");
  const phoneNumber = useRef("");
  const dialingCode = useRef("");
  const userStatusCode = useRef("");
  const [verificationModel, setVerificationCode] = useState(verificationData);
  const [verifyKeyError, setVerifyKeyError] = useState('');
  const [formResponseError, setFormResponseError] = useState('');
  const isSuccessResponse = useRef(false);
  const [loading, setLoading] = useState(false);
  const renewAccessToken = useRenewAccessToken();
  const [isVerifying, setIsVerifying] = useState(false);

  const checkValidation = (field: string) => {
    let isValid = true;
    if (field === "verifyKeyError") {
      if (codes.every(x => x === "")) {
        setVerifyKeyError('Please enter OTP');
        isValid = false;
      } else {
        setVerifyKeyError('');
      }
    }
    return isValid;
  };

  useEffect(() => {
    if (isUserStatusHasPermission(5) || isNotNullUndefinedBlank(storage.getLoginProvider()) || isNotNullUndefinedBlank(storage.getUserExchangeStatusId())) {
      navigate(statusWiseOnboardingRedirectToPage());
    }
    userId.current = storage.getUserId() ?? "";
    email.current = storage.getEmailId() ?? "";
    phoneNumber.current = storage.getPhoneNumber() ?? "";
    dialingCode.current = storage.getDailingCode() ?? "";

    const isCodesEmpty = codes.join('').length === 0;
    const isErrorEmpty = ((formResponseError ?? "") === "");
    const isUserIdPresent = ((userId.current ?? "") !== "");
    const isEmailPresent = ((email.current ?? "") !== "");
    const isPhoneNoPresent = ((dialingCode.current ?? "") !== "" && (phoneNumber.current ?? "") !== "");
    if (isCodesEmpty && !isVerifying && isErrorEmpty && isUserIdPresent && (isEmailPresent || isPhoneNoPresent)) {
      verificationData.userId = userId.current;
      verificationData.email = email.current;
      if (isEmailPresent && isPhoneNoPresent) {
        verificationData.codeType = "both"
      } else if (isEmailPresent) {
        verificationData.codeType = "email"
      } else {
        verificationData.codeType = "sms"
      }
      setVerificationCode(verificationData);
      onSendVerificationCode();
    } else if (isVerifying && isCodesEmpty) {
      setIsVerifying(false);
    }
    autoVerifyOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes]);

  const onSendVerificationCode = async () => {
    if (isUserStatusHasPermission(1)) {
      sendVerificationCodeMutation.mutate(verificationModel);
    }
  };

  const sendVerificationCodeMutation = useSendVerificationCode({
    onSuccess: () => {
      userStatusCode.current = "OTP_SENT";
      updateUserProcessStatusMutation.mutate({ "userId": userId.current, "statusCode": userStatusCode.current });
      setTimeout(() => {
        setLoading(false);
        setFormResponseError("");
      }, 5000);
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const reSendVerificationCodeMutation = useSendVerificationCode({
    onSuccess: () => {
      isSuccessResponse.current = true;
      setFormResponseError("OTP sent");
      setTimeout(() => { isSuccessResponse.current = false; setFormResponseError(""); }, 5000);
    },
    onError: (error) => {
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const onVerify = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (checkValidation("verifyKeyError")) {
      setLoading(true);
      let code = codes.join('');
      storage.clearUserReferrerCode();
      setIsVerifying(true);
      verifiyVerificationCodeMutation.mutate({
        userId: userId.current,
        email: email.current,
        verificationCode: code.replace(/\s+/g, ''),
        codeType: verificationModel.codeType
      });
    }
  };


  const verifiyVerificationCodeMutation = useVerifyUserVerificationCode({
    onSuccess: (response) => {
      if (response.statusCode === 200) {
        if (isNotNullUndefined(response.data)) {
          storage.setToken(response.data.token);
          storage.setRefreshToken(response.data.refreshToken);
          fetchUser();
        }
        userStatusCode.current = "OTP_VERIFIED";
        updateUserProcessStatusMutation.mutate({ "userId": userId.current, "statusCode": userStatusCode.current });
      }
      else {
        setLoading(false);
        setFormResponseError(response.statusMessage);
      }
      setCode(Array(6).fill(""));
    },
    onError: (error) => {
      setLoading(false);
      setIsVerifying(false);
      setCode(Array(6).fill(""));
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const fetchUser = async () => {
    fetchUserMutation.mutate({});
  };

  const fetchUserMutation = useFetchUser({
    onSuccess: async (response: IResponse) => {
      if (response.statusCode === 200) {
        if (response.data.exchangeAccounts.length > 0) {
          storage.setUserExchangeAccountId(response.data.exchangeAccounts[0].userExchangeAccountId);
          storage.setExchangeId(response.data.exchangeAccounts[0].exchangeId);
          navigate('/account/asset/setup?id=' + response.data.exchangeAccounts[0].userExchangeAccountId);
        }
        else
          navigate('/connect/binance/info');
      }
      if (!isTokenValid(response)) {
        const tokenRenewed = await renewAccessToken();
        if (isNotNullUndefined(tokenRenewed) && tokenRenewed.statusCode === 200) {
          fetchUser();
        }
      }
      else {
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const updateUserProcessStatusMutation = useUpdateUserProcessStatus({
    onSuccess: (response: IResponse) => {
      if (response.statusCode === 200) {
        if (userStatusCode.current === "OTP_SENT")
          storage.setUserStatusId(4);
        if (userStatusCode.current === "OTP_VERIFIED") {
          storage.setUserStatusId(3);
          updateUserProcessStatusMutation.mutate({ "userId": userId.current, "statusCode": userStatusCode.current });
          setTimeout(() => {
            userStatusCode.current = "ACTIVE";
            updateUserProcessStatusMutation.mutate({ "userId": userId.current, "statusCode": userStatusCode.current });
          }, 100);
          navigate('/connect/binance/info');
        }
        if (userStatusCode.current === "ACTIVE")
          storage.setUserStatusId(1);
      }
      else {
        setLoading(false);
        setFormResponseError(response.statusMessage);
      }
    },
    onError: (error) => {
      setLoading(false);
      Sentry.captureException(error);
      setFormResponseError(error.message);
    }
  });

  const onResend = () => {
    let codeType = "";
    if (isNotNullUndefinedBlank(dialingCode.current) && isNotNullUndefinedBlank(phoneNumber.current) && isNotNullUndefinedBlank(email.current)) {
      codeType = "both";
    } else if (isNotNullUndefinedBlank(dialingCode.current) && isNotNullUndefinedBlank(phoneNumber.current)) {
      codeType = "sms";
    } else if (isNotNullUndefinedBlank(email.current)) {
      codeType = "email";
    }
    reSendVerificationCodeMutation.mutate({ userId: userId.current, email: email.current, codeType: codeType });
    resetTimer();
    setCode(Array(codes.length).fill(""));
  };

  const onRedirectToLogin = () => {
    window.localStorage.clear();
    navigate('/');
  };

  const autoVerifyOTP = async () => {
    if (codes.join('').length === 6 && !isVerifying) {
      await onVerify();
    }
  };

  const onRedirectToRegisterPage = () => {
    storage.clearUserStatusId();
    navigate("/registration");
  }

  return (
    <div className="bg-black-russian min-h-screen">
      <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto max-h-full min-h-screen">
        <div className='hidden lg:block'>
          <div className="left mx-auto w-full xl:p-0 bg-pattern-1 bg-cover bg-no-repeat bg-center h-full">
            <div className='flex justify-center'>
              <img
                className="mt-[75px] lg:w-56 w-36 sm:w-48 flex"
                src={Images.ailtralogo}
                alt="Ailtra Logo" draggable="false" />
            </div>
            <div className='flex justify-center items-center'>
              <div className='w-[562px]'>
                <div className='flex justify-center bg-contain my-8 w-full lg:my-[100px]'>
                  <img
                    src={Images.emailbox2} className="w-1/2 lg:w-2/3"
                    alt="Email Box" draggable="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right bg-white flex justify-center items-center rounded-t-none p-4 lg:p-0 lg:rounded-tl-3xl lg:rounded-bl-3xl lg:rounded-tr-none h-full">
          <div className="w-[340px] 2xl:w-[388px]">
            <div className="flex justify-center">
              <img src={Images.email} alt="Email" className="md:w-72 w-56" />
            </div>
            <div className="mt-14">
              <h4 className="text-lg text-center font-bold">
                Verify Your Authorization
              </h4>
              <div>
                <p className="text-center text-sm md:text-15 font-normal sm:leading-6 text-grey-storm mt-6">
                  Enter the 6-digit verification code sent to
                  {email.current !== "" && dialingCode.current !== "" && phoneNumber.current !== "" ? (
                    <>
                      <span className="font-semibold border-b border-grey-storm mx-1">{dialingCode.current} {phoneNumber.current}</span>
                      and
                    </>
                  ) : null}
                  <span className="font-semibold border-b border-grey-storm mx-1">{email.current}</span>
                  to confirm your authorization for trading.
                </p>
              </div>
            </div>
            <form onSubmit={onVerify}>
              <div className="relative">
                <div
                  id="otp"
                  className="flex flex-row justify-center text-center px-2 mt-10">
                  {codes.map((digit, index) => (
                    <input
                      key={index + 1}
                      className="m-1 md:m-2 border border-blue-rock h-9 w-9 md:h-12 md:my-2 md:mx-3 md:w-12 text-center form-control rounded"
                      type="text"
                      id={`code-${index}`}
                      maxLength={1}
                      onBlur={() => checkValidation("verifyKeyError")}
                      value={digit}
                      onChange={(e) => handleOtpInput(e, index)}
                      onKeyDown={(e) => handleOtpKeyDown(e, index)}
                      onPaste={(e) => handleOtpPaste(e, index)}
                      //onKeyUp={(e) => autoVerifyOTP(e)}
                      autoFocus={false}
                    />
                  ))}
                </div>
                {verifyKeyError && <p className="text-red-500 text-xs mt-1 font-semibold text-center absolute w-full">{verifyKeyError}</p>}
              </div>
              <div className="mt-16 relative max-[1440px]:mt-8">
                <button
                  id="btnVerifyOtp"
                  type="submit"
                  disabled={loading}
                  className={`relative w-full flex items-center justify-center py-3.5 cursor-pointer font-bold text-sm transition-all text-white mt-4 sm:mt-8.5 btn-hover-animation focus-visible:bg-[right_center] hover:bg-[right_center] outline-none ${loading ? 'bg-gradient-hover opacity-60' : 'bg-gradient-hover'}`} >
                  {loading ? (
                    <p className='flex items-center'>
                      <span className='w-4 h-4 border-2 border-dashed animate-spin rounded-full mr-2'>
                      </span>
                      Verifying OTP...
                    </p>
                  ) : (
                    'AGREED & PROCEED'
                  )}
                </button>
                <p className={`${!isSuccessResponse.current ? 'text-persian' : 'text-emerald'} text-xs mt-1 text-center font-semibold absolute w-full`} id="formErrorMsg">{formResponseError}</p>
                <div className="mt-6 flex justify-center items-center mb-10">
                  {resendTimer > 0 ? (
                    <p className="font-normal text-13 text-gray-400" id="resendOtpTimer">
                      Resend OTP in {resendTimer}s
                    </p>
                  ) : (
                    <button
                      id="btnResendOtp"
                      onClick={onResend} type="button"
                      className="font-normal text-13 text-gray-400">
                      Resend OTP
                    </button>
                  )}
                </div>
                <div className="mt-10">
                  <button className="w-full flex justify-center mb-8 lg:mb-12"
                    id="redirectToRegisterPage"
                    onClick={() => onRedirectToRegisterPage()}>
                    {/* border-4 border-[#f9e0d5] */}
                    <p className="text-base font-normal text-center underline">
                      {email.current !== "" && phoneNumber.current !== "" ? "Change email address & mobile number" : "Change email address"}
                    </p>
                  </button>

                  <div className=" w-full justify-center flex align-middle items-center select-none">
                    <button className="outline-none cursor-pointer w-max flex"
                      id="redirectToLogin"
                      onClick={() => onRedirectToLogin()}>
                      <img src={Images.arrowLeft} className="mr-3 cursor-pointer" alt="Arrow left" />
                      <span className="cursor-pointer">Back to login</span>
                    </button>
                  </div>

                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Authorization;
