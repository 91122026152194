const validateNotEmpty = (value) => {
    return value.toString().trim() !== "" && value !== null && value !== undefined;
};

const validateEmailFormat = (value) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?!.*[._]{2})([a-zA-Z0-9_]+[._]?){1,5}[a-zA-Z0-9_]+@[a-zA-Z][a-zA-Z0-9_-]*(?<!-)\.[a-zA-Z]{2,}$/;
    return regex.test(value);
};

export {
    validateNotEmpty,
    validateEmailFormat
};