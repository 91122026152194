import { storage } from "../utils/storage";

const APIUrl = "https://devapi.ailtra.ai/api/v1/";
const WebSocketUrl = "wss://devapi.ailtra.ai/ws/v1/";

export const allowedDomainUrls = [
    "localhost:3000",
    /^http:\/\/localhost:7087\/api\/v1\//, //for localhost, change port number if needed
    "https://dev.ailtra.ai/",
    /^https:\/\/devapi\.ailtra\.ai\/api\/v1\//, //for dev.ailtra.ai 
]; // add more if needed.


export function apiUrl(endpoint: string): string {
    let url = `${APIUrl}${endpoint}`;
    return url;
}

export function webSocketUrl(endpoint: string): string {
    let url = `${WebSocketUrl}${endpoint}`;
    return url;
}

type Headers = {
    'Content-Type': string;
    Authorization?: string;
    refreshToken?: string;
};

export async function post(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function patch(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function put(url: string, data: any, withToken: boolean = true): Promise<any> {
    const headers: Headers = {
        'Content-Type': 'application/json',
    };
    if (withToken) {
        headers.Authorization = `Bearer ${storage.getToken()}`;
    }
    return fetch(apiUrl(url), {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export async function get(url: string): Promise<any> {
    const response = await fetch(apiUrl(url), {
        headers: {
            Authorization: `Bearer ${storage.getToken()}`,
            refreshToken: `${storage.getRefreshToken()}`
        },
    });
    return response.json();
}

// export function handleError(error: any) {
//     // var message = '';
//     // if (error.error !== null && (error.error.error !== null && (error.error.error.message !== undefined || error.error.error.message !== null || error.error.error.message !== '')))
//     //     message = error.error.error.message;
//     // else if (error.error !== null && (error.error.message !== undefined || error.error.message !== null || error.error.message !== ''))
//     //     message = error.error.message;
//     // else
//     //     message = error.message;

//     return { statusCode: error.response.data.statusCode, statusMessage: error.response.data.statusMessage };
// }
