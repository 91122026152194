import Images from '../assets/images';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState<string>("dashboard");
    const navigate = useNavigate();

    useEffect(() => {
        const selectedMenuName = localStorage.getItem("selectedMenu");
        if (selectedMenuName) {
            setSelectedMenu(selectedMenuName);
        }
    }, []);

    const handleModeChange = () => {
        setIsDarkMode(!isDarkMode);
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const onClickNavMenu = (menuName: string) => {
        setSelectedMenu(menuName);
        localStorage.setItem("selectedMenu", menuName);

        if (menuName === "dashboard") {
            navigate("/dashboard");
        }
        else if (menuName === "coinprofile") {
            navigate("/account/asset/BTCUSDT");
        }
    };

    return (
        <div className="relative min-h-screen  md:flex">
            <button
                className="block md:hidden fixed z-50 top-5 left-4 items-center justify-center"
                onClick={toggleSidebar}>
                {/* Icon for Toggle Button */}
                {isOpen ? (
                    // Icon for close state
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        {/* <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /> */}
                    </svg>
                ) : (
                    // Icon for open state
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                )}
            </button>
            <div className='fixed z-50'>
                <div className={`w-[210px] md:w-[0px] md:py-0 md:px-0 fixed inset-y-0 left-0 transform md:relative transition-transform duration-300 ease-in-out z-50 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="sidebar transition delay-700 duration-300 ease-in-out md:delay-700 md:duration-300 md:ease-in-out w-[210px] md:w-[96px] lg:w-[264px] fixed min-h-screen bg-grey-jaguar">
                        <div className="sidebar-header flex items-center justify-center">
                            <div className="inline-flex">
                                <img
                                    src={Images.ailtralogo}
                                    className="w-40 mt-8  hidden lg:inline-block"
                                    alt="Ailtralogo"
                                />
                                <img
                                    src={Images.ailtralogo2}
                                    className="w-12 mt-8 hidden md:inline-block lg:hidden"
                                    alt="Ailtralogo"
                                />
                            </div>
                        </div>
                        <button
                            className="block md:hidden fixed z-50 top-5 right-4"
                            onClick={toggleSidebar}>
                            {/* Icon for Toggle Button */}
                            {isOpen ? (
                                // Icon for close state
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                // Icon for open state
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6 text-white"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor">
                                </svg>
                            )}
                        </button>
                        <div className='block md:hidden px-6 mt-12'>
                            <div className='flex justify-between items-center'>
                                <div>
                                    <img
                                        src={Images.ailtralogo2}
                                        className="w-12 inline-block lg:hidden"
                                        alt="Ailtralogo"
                                    />
                                </div>
                                <div className="">
                                    <img className=" w-5 xl:w-6" src={Images.bellbing} alt="Bellbing" />
                                </div>
                                <div className="flex cursor-pointer">
                                    <button onClick={handleModeChange} className={`sun ${isDarkMode ? "bg-gradient w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5 flex justify-center" : "bg-grey-ebony-Clay opacity-50 hidden lg:block w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5"} `}>
                                        <img className="" src={Images.sun} alt="Sun" />
                                    </button>
                                    <button onClick={handleModeChange} className={`moon ${isDarkMode ? "bg-grey-ebony-Clay opacity-50 hidden lg:block w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5" : "bg-gradient w-8.5 h-[30px] md:w-[42px] md:h-8.5 px-2 py-1.5 flex justify-center"}`}>
                                        <img className="" src={Images.moon} alt="Moon" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="sidebar-content px-6 py-6 pt-6 lg:px-8 lg:py-6 lg:pt-8">
                            <ul className="flex flex-col w-full">
                                <li className="my-px cursor-pointer" key={"dashboard"} onClick={() => onClickNavMenu("dashboard")}>
                                    <button type='button' className={`flex w-full flex-row items-center h-12 px-3 rounded-lg ${selectedMenu === "dashboard" ? 'bg-gradient' : ''}`}>
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.dashboard} alt="Dashboard" />
                                        </span>
                                        <span className="ml-3 text-sm text-white font-semibold block md:hidden lg:block">
                                            Dashboard
                                        </span>
                                    </button>
                                </li>
                                <li className="my-px mt-2 cursor-pointer" key={"coinprofile"} onClick={() => onClickNavMenu("coinprofile")}>
                                    <button type='button' className={`flex w-full flex-row items-center h-12 px-3 rounded-lg ${selectedMenu === "coinprofile" ? 'bg-gradient' : ''}`}>
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.coinprofilestar} alt="Coinprofilestar" />
                                        </span>
                                        <span className="ml-3 text-sm text-white font-semibold block md:hidden lg:block">
                                            Coin
                                        </span>
                                    </button>
                                </li>
                                {/* <li className="my-px mt-8" key={"security"}>
                                    <a className="flex flex-row items-center h-12 px-3 rounded-lg">
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.shieldcheck} alt="Shieldcheck" />
                                        </span>
                                        <span className="ml-3 text-sm text-grey-rock-blue font-semibold block md:hidden lg:block">
                                            Security
                                        </span>
                                    </a>
                                </li>

                                <li className="my-px mt-8">
                                    <a className="flex flex-row items-center h-12 px-3 rounded-lg">
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.userid} alt="Userid" />
                                        </span>
                                        <span className="ml-3 text-sm text-grey-rock-blue font-semibold block md:hidden lg:block">
                                            Identification
                                        </span>
                                    </a>
                                </li>
                                <li className="my-px mt-8" key={"payment"}>
                                    <a className="flex flex-row items-center h-12 px-3 rounded-lg">
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.wallet2} alt="Wallet" />
                                        </span>
                                        <span className="ml-3 text-sm text-grey-rock-blue font-semibold block md:hidden lg:block">
                                            Payment
                                        </span>
                                    </a>
                                </li>
                                <li className="my-px mt-8" key={"rewards"}>
                                    <a className="flex flex-row items-center h-12 px-3 rounded-lg">
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.crownstar} alt="Crownstar" />
                                        </span>
                                        <span className="ml-3 text-sm text-grey-rock-blue font-semibold block md:hidden lg:block">
                                            Rewards
                                        </span>
                                    </a>
                                </li>
                                <li className="my-px mt-8" key={"settings"}>
                                    <a className="flex flex-row items-center h-12 px-3 rounded-lg">
                                        <span className="flex items-center justify-center text-lg">
                                            <img src={Images.settings} alt="Settings" />
                                        </span>
                                        <span className="ml-3 text-sm text-grey-rock-blue font-semibold md:hidden lg:block">
                                            Settings
                                        </span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;